import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Buynow from "./Buynow";

export default function Index() {
  return (
    <>
      <Header />
      <div className="site-wraper">
        <Buynow />
        <img src="images/dec1.webp" width="100%" alt="" />
        <section id="why-sec" class="how-wrok section-padding">
          <div class="container-fluid">
            <div class="headings w-50 mx-auto text-center mb-5">
              <h4>Why Choose MEOW AI</h4>
            </div>
            <div class="row how-wrok-box">
              <div class="col-md-3">
                <article>
                  <figure>
                    <img src="images/ic1.png" alt="" />
                  </figure>
                  <figcaption>
                    <h4>Unparalleled AI-Powered Analytics</h4>
                    <p>
                      MEOW AI employs advanced AI models to decipher intricate data, delivering crucial insights that keep you ahead in the dynamic world of
                      digital assets.
                    </p>
                  </figcaption>
                </article>
              </div>
              <div class="col-md-3">
                <article>
                  <figure>
                    <img src="images/ic2.png" alt="" />
                  </figure>
                  <figcaption>
                    <h4>Elite Trader Unmasking</h4>
                    <p>Uncover the footprints of the most profitable traders across various blockchains with MEOW AI's comprehensive toolset.</p>
                  </figcaption>
                </article>
              </div>
              <div class="col-md-3">
                <article>
                  <figure>
                    <img src="images/ic3.png" alt="" />
                  </figure>
                  <figcaption>
                    <h4>Real-Time Crypto/NFT Market Intelligence</h4>
                    <p>
                      MEOW AI provides real-time data and predictive analytics, empowering you to identify lucrative opportunities and potential risks
                      instantly.
                    </p>
                  </figcaption>
                </article>
              </div>
              <div class="col-md-3">
                <article>
                  <figure>
                    <img src="images/ic4.png" alt="" />
                  </figure>
                  <figcaption>
                    <h4>Secure and User-Friendly Platform</h4>
                    <p>
                      Safety and simplicity are core tenets of MEOW AI. Our platform ensures the highest levels of data security with an intuitive user
                      interface.
                    </p>
                  </figcaption>
                </article>
              </div>
            </div>
          </div>
        </section>
        <img src="images/dec1.webp" width="100%" alt="" />

        <section id="features" class="features-sec section-padding">
          <div class="container">
            <div class="headings w-75 mx-auto text-center mb-5">
              <h4>MEOW AI FEATURES</h4>
              <p>Discover a range of features live on the mainnet, with others in the pipeline:</p>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="images/svg1.svg" width="100px" alt="" />
                    </figure>
                    <h4>CheckBoard</h4>
                    <p>
                      A comprehensive dashboard providing an overview of the digital asset market, key metrics, trends, and real-time data on cryptocurrencies,
                      DEXs, and NFTs.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="images/svg2.svg" width="100px" alt="" />
                    </figure>
                    <h4>Token Analytics & Charts</h4>
                    <p>Delve into token-specific data with advanced analytics and interactive charts, facilitating informed investment decisions.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="images/svg3.svg" width="100px" alt="" />
                    </figure>
                    <h4>Crypto Whales Tracker</h4>
                    <p>Monitor major crypto investors' activities, gaining insights into their transactions, holdings, and strategies.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="images/svg4.svg" width="100px" alt="" />
                    </figure>
                    <h4>NFT Whales Tracker</h4>
                    <p>Keep an eye on top players in the NFT market, tracking their purchases, sales, and collections to identify trends and opportunities.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="images/svg5.svg" width="100px" alt="" />
                    </figure>
                    <h4>Top Crypto Traders [PRO]</h4>
                    <p>Explore the strategies and performance of the most successful crypto traders to improve your own trading skills.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="images/svg6.svg" width="100px" alt="" />
                    </figure>
                    <h4>InsightsGPT[PRO]</h4>
                    <p>An AI-powered DEX tracker delivering real-time insights by analyzing Smart Money wallets and DEX transactions.</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="images/svg7.svg" width="100px" alt="" />
                    </figure>
                    <h4>Address Analyzer</h4>
                    <p>Effortlessly examine captivating wallets in the crypto space, visualizing all trading activities and evaluating Profit/Loss metrics</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="images/svg8.svg" width="100px" alt="" />
                    </figure>
                    <h4>Token Unlocks Dashboard</h4>
                    <p>Stay informed about upcoming token unlocks and vesting events, anticipating potential market movements</p>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="images/svg9.svg" width="100px" alt="" />
                    </figure>
                    <h4>Telegram Bots Section</h4>
                    <p>Discover how our Telegram Bots can enhance your trading game.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0 mt-5 images-row">
              <div class="col-md-3 p-0">
                <img src="images/img5.jpeg" width="100%" alt="" />
              </div>
              <div class="col-md-3 p-0">
                <img src="images/img6.jpeg" width="100%" alt="" />
              </div>
              <div class="col-md-3 p-0">
                <img src="images/img7.png" width="100%" alt="" />
              </div>
              <div class="col-md-3 p-0">
                <img src="images/img8.jpeg" width="100%" alt="" />
              </div>
            </div>
          </div>
        </section>

        <div class="group-sec section-padding">
          <section class="presale-box pt-0">
            <div class="container-fluid">
              <div class="headings w-50 mx-auto text-center mb-5">
                <h4>MEOW AI PRESALE CATEGORIES</h4>
                <h4>Unlock Your Crypto Potential</h4>
                <p>
                  Invest in the MEOW AI presale and unlock exclusive features based on your commitment. Choose your category and access a suite of powerful
                  tools tailored to elevate your crypto and NFT trading experience.
                </p>
              </div>
              <div class="row presale-cat m-0">
                <div class="col-md-4 p-0">
                  <div class="shape-box">
                    <div class="shape-box-in">
                      <figure>
                        <img src="images/img10.jpeg" alt="" />
                      </figure>
                      <h4>ROOKIE CATEGORY</h4>
                      <h5>Unlock the Basics with 0.1 ETH Purchase</h5>
                      <ul>
                        <li>
                          <i class="ri-check-double-line"></i>Token Analytics
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>NFT Analytics
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Crypto Whales Tracker (BASIC)
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>NFT Whales Tracker
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>New Pairs
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Trending Pairs
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Token Unlocks
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>News Aggregator
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>IDO Calendar
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Multicharts
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Watchlist
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Wallet Analyzer (BASIC)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 p-0">
                  <div class="shape-box">
                    <div class="shape-box-in">
                      <figure>
                        <img src="images/img3.jpeg" alt="" />
                      </figure>
                      <h4>EXPERT CATEGORY </h4>
                      <h5>Your Expertise with 0.5 ETH Purchase</h5>
                      <ul>
                        <li>
                          <i class="ri-check-double-line"></i>Top Crypto Traders
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Smart Money Token Leaderboard
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Top NFT Traders
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Smart Money NFT Leaderboard
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Early Access to New Analytics Tools
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Wallet Analyzer (PRO)
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>Crypto Whales Tracker (PRO)
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 p-0">
                  <div class="shape-box">
                    <div class="shape-box-in">
                      <figure>
                        <img src="images/img8.jpeg" alt="" />
                      </figure>
                      <h4>ORACLE CATEGORY</h4>
                      <h5>the Markets with 1 ETH or More</h5>
                      <ul>
                        <li>
                          <i class="ri-check-double-line"></i>InsightsGPT Smart Alerts
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>DexCheck Premium Group
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>DexCheck Ventures (Private Sale Deals Access)
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>SmartFolio (PRO-AI)
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>AI-Arbitrage Scanner (COMING SOON)
                        </li>
                        <li>
                          <i class="ri-check-double-line"></i>DAO Governance
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row d-flex justify-content-center mt-4">
                <a className="btn centerr btn-blue " href="#home">
                BUY NOW
                </a>
              </div>
            </div>
          </section>
        </div>

        <section class="roadmap-sec section-padding">
          <div class="container">
            <div class="headings text-center mb-5">
              <h4>Roadmap</h4>
            </div>
            <div class="roadmap-li">
              <ul>
                <li>
                  <h4>Phase 1</h4>
                  <ul>
                    <li>
                      <i class="ri-check-double-line"></i> Platform v.1
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Presale launch and DEX/CEX listing
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Top Crypto Traders [PRO]
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Top NFT Traders [PRO]
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Smart Money leaderboard [PRO]
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Community Building
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Partners Expansion
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Enhance user interface and user experience (UI/UX)
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Phase 2</h4>
                  <ul>
                    <li>
                      <i class="ri-check-double-line"></i> Influencers/KOLs collaborations
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Initial Marketing Campaign
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> InsightsGPT: AI smart money insights, intelligent alerts
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Telegram Bots: Wallet Tracking/Sniper/Multi-wallet Swaps
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Integration of additional blockchain networks for a broader range of market data.
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Phase 3</h4>
                  <ul>
                    <li>
                      <i class="ri-check-double-line"></i> Mobile app for iOS and Android
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> AI-MeowFolio [PRO]: Notify users of significant sentiment changes for selected tokens based on order
                      flow history.
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> BRC-20 Tracker
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> DAO Governance launched
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Multi-Language Support
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Phase 4</h4>
                  <ul>
                    <li>
                      <i class="ri-check-double-line"></i> NarrativeAI [PRO]: AI-powered Insights on emerging token category narratives and potential
                      opportunities
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> IQ Tracker [PRO] : Curated dashboard for tracking VCs / Influencer on-chain activity
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Analytics for NFT rarity and scores, providing deeper insights into the value of NFTs
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Crypto derivatives market analytics: analytics tools for crypto options, futures, and other
                      derivatives
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> AI-Arbitrage Scanner [PRO] : Identify profitable arbitrage opportunities across various exchanges
                      (DEXs and CEXs)
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Expand the mobile app to include push notifications for real-time alerts and updates
                    </li>
                    <li>
                      <i class="ri-check-double-line"></i> Platform new features based on DAO votes
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <img src="images/dec1.webp" width="100%" alt="" />
        <section class="utilities-sec section-padding pt-0" id="utilities-sec">
          <div class="container">
            <div class="headings text-center mb-5">
              <h4>$MEOW Token Utility</h4>
              <p>
                $MEOW, the native token of MEOW AI, plays a pivotal role in the MEOW AI ecosystem, providing users with a range of benefits and utilities to
                enhance their experience on the platform. The $MEOW token is strategically designed to incentivize users to actively engage with the platform,
                contribute to its liquidity, and actively participate in its governance.
              </p>
            </div>
            <div class="row utilities-sec-box">
              <div class="col-md-7">
                <article>
                  <h4>Unlock PRO Analytics Tools</h4>
                  <p>
                    TThe primary utility of holding $MEOW tokens is the exclusive access to MEOW AI's PRO analytics tools. By staking their $MEOW tokens, users
                    unlock advanced data analytics tools that go beyond regular features. These exclusive tools provide invaluable insights into the crypto and
                    NFT market, empowering users to make informed decisions and stay ahead of market trends.
                  </p>
                </article>
              </div>
              <div class="col-md-5">
                <article>
                  <h4>Governance Participation</h4>
                  <p>
                    $MEOW token holders are granted governance rights, allowing them to actively participate in the decision-making processes of the MEOW AI
                    platform. Token holders can vote on proposals related to platform upgrades, fee structures, token burns, and other crucial decisions. This
                    democratic approach ensures that the MEOW AI community has a say in shaping the future of the platform.
                  </p>
                </article>
              </div>
              <div class="col-md-5">
                <article>
                  <h4>Private Sales Access - MEOW Launchpad</h4>
                  <p>
                    By staking a designated amount of $MEOW tokens, users gain access to the MEOW Launchpad, where they can participate in exclusive Initial
                    Private Sale Offerings (IPSO). This grants users the opportunity to secure Private Sale prices for Initial Dex Offerings (IDOs), providing a
                    strategic advantage in PreSale investments and the potential to invest in high-potential projects early on.
                  </p>
                </article>
              </div>
              <div class="col-md-7">
                <article>
                  <h4>Staking Rewards</h4>
                  <p>
                    MEOW AI encourages users to stake their $MEOW tokens, offering rewards in the form of additional $MEOW tokens or other digital assets.
                    Staking not only provides users with additional incentives but also contributes to the overall liquidity of the $MEOW token, fostering a
                    robust and engaged community.
                  </p>
                </article>
              </div>
              <div class="col-md-12">
                <article>
                  <h4>Community Building and Engagement</h4>
                  <p>
                    Beyond the tangible benefits, holding $MEOW tokens signifies active participation in the MEOW AI community. Users become part of a dynamic
                    ecosystem where collective engagement drives the platform's growth and success.
                  </p>
                </article>
              </div>
            </div>
          </div>
        </section>
        <img src="images/dec1.webp" width="100%" alt="" />

        <section class="tokenomics section-padding" id="tokenomics-sec">
          <div class="container">
            <div class="headings text-center mb-5">
              <h4>Tokenomics</h4>
              <p>
                Discover the foundation of MEOW's ecosystem through a transparent distribution of $MEOW tokens, with a total supply of 100 billion, designed to
                foster sustainable growth, community engagement, and the continuous development of our cutting-edge platform.
              </p>
            </div>
            <div class="tokenomics__items">
              <div class="tokenomics__item token-item">
                <div class="block token-item__row">
                  <span class="text">
                    Participate in MEOW's early stages by acquiring $MEOW tokens through our presale. This allocation fuels initial development, ensuring a
                    vibrant and engaged community from the onset.
                  </span>
                  <b>Presale Allocation (40%)</b>
                </div>
              </div>
              <div class="tokenomics__item token-item">
                <div class="block token-item__row">
                  <span class="text">
                    Allocate 5% of tokens for strategic marketing initiatives, user acquisition, and building partnerships. This ensures a robust presence in
                    the crypto space, accelerating MEOW's growth.
                  </span>
                  <b>Marketing, Acquisition, and Partnerships (5%)</b>
                </div>
              </div>
              <div class="tokenomics__item token-item">
                <div class="block token-item__row">
                  <span class="text">
                    Dedicate 5% of tokens to the team and development, recognizing the efforts of our talented team and fostering ongoing innovation and
                    improvement within the MEOW AI ecosystem.
                  </span>
                  <b>Team and Development (5%)</b>
                </div>
              </div>
              <div class="tokenomics__item token-item">
                <div class="block token-item__row">
                  <span class="text">
                    Reserve 3% of tokens for our valued partners and advisors, contributing their expertise to propel MEOW towards success.
                  </span>
                  <b>Partners and Advisors (3%)</b>
                </div>
              </div>
              <div class="tokenomics__item token-item">
                <div class="block token-item__row">
                  <span class="text">
                    Allocate 10% of tokens for the growth and expansion of the MEOW AI ecosystem. This includes incentives for developers, community-driven
                    initiatives, and activities enhancing the overall ecosystem.
                  </span>
                  <b>Ecosystem Growth (10%)</b>
                </div>
              </div>
              <div class="tokenomics__item token-item">
                <div class="block token-item__row">
                  <span class="text">
                    Ensure liquidity and stability in the $MEOW market by allocating 37% of tokens to the liquidity pool. This provides a solid foundation for
                    trading and enhances overall liquidity.
                  </span>
                  <b>Liquidity Pool (37%)</b>
                </div>
              </div>
            </div>
          </div>
        </section>
        <img src="images/dec1.webp" width="100%" alt="" />
        <div class="row m-0 mt-5 images-row">
          <div class="col-md-3 p-0">
            <img src="images/img9.jpeg" width="100%" alt="" />
          </div>
          <div class="col-md-3 p-0">
            <img src="images/img10.jpeg" width="100%" alt="" />
          </div>
          <div class="col-md-3 p-0">
            <img src="images/img4.jpeg" width="100%" alt="" />
          </div>
          <div class="col-md-3 p-0">
            <img src="images/img3.jpeg" width="100%" alt="" />
          </div>
        </div>
        <section class="team-sec section-padding" id="team-sec">
          <div class="container-fluid">
            <div class="headings w-50 mx-auto text-center mb-5">
              <h4>Meet the MEOW AI Team</h4>
              <p>
                Embark on the MEOW journey with a team dedicated to revolutionizing the Web3 landscape. Our diverse and talented professionals are the driving
                force behind MEOW AI's innovation and success.
              </p>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="https://info.itemis.com/hubfs/Blog/Authors/Katharina_Lattenkamp_300x300px_DSC00242.jpg" alt="" />
                    </figure>
                    <figcaption>
                      <h4>Jane Doe</h4>
                      <span>CEO</span>
                      <p>
                        With extensive experience in blockchain technology and strategic leadership, Jane leads MEOW AI with a vision for a user-friendly crypto
                        experience.
                      </p>
                    </figcaption>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTDJzEaxLN-jGRYYUO65pWu7Q9GXoNt4LUSSA&usqp=CAU" alt="" />
                    </figure>
                    <figcaption>
                      <h4>Bob Smith </h4>
                      <span>CTO</span>
                      <p>
                        A seasoned technologist in software engineering and blockchain development, Bob shapes MEOW AI's technological foundation for
                        scalability and security.
                      </p>
                    </figcaption>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="images/emily.jpg" alt="" />
                    </figure>
                    <figcaption>
                      <h4>Emily Taylor </h4>
                      <span>Head of Marketing</span>
                      <p>
                        An expert in digital marketing and brand strategy, Emily drives MEOW AI's visibility and growth with innovative and data-driven
                        strategies.
                      </p>
                    </figcaption>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="shape-box">
                  <div class="shape-box-in">
                    <figure>
                      <img src="https://t3.ftcdn.net/jpg/03/28/26/86/360_F_328268657_OSSRDoZeO6isXj3QxRUTaXlmGF6lLmyZ.jpg" alt="" />
                    </figure>
                    <figcaption>
                      <h4>Alex Johnson </h4>
                      <span>Blockchain Developer</span>
                      <p>
                        A skilled blockchain developer committed to staying at the forefront of technology, Alex ensures MEOW AI remains on the cutting edge.
                      </p>
                    </figcaption>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-center mt-5">
                <h4>
                  The MEOW AI team is united by a shared vision of making Web3 accessible and user-friendly. With a diverse skill set and a passion for
                  innovation, we are dedicated to creating a seamless and intelligent crypto experience for our users. Join us on this exciting journey! 🌐🚀
                </h4>
              </div>
            </div>
          </div>
        </section>
      </div>
      <img src="images/dec1.webp" width="100%" alt="" />
      <Footer />
    </>
  );
}
