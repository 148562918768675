import "@rainbow-me/rainbowkit/styles.css";
import { darkTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, goerli } from "wagmi/chains";
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { publicProvider } from "wagmi/providers/public";

import { metaMaskWallet, coinbaseWallet, walletConnectWallet, phantomWallet } from "@rainbow-me/rainbowkit/wallets";
import UserProvider from "./context/UserContext";
import { infuraProvider } from "wagmi/providers/infura";

const projectId = "3f5eddd542dc7da6cf228d19a498eda1";

const { chains, publicClient } = configureChains([mainnet], [infuraProvider({ apiKey: "e9095e17fe72422e82c833b6061abc13" }), publicProvider()]);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      phantomWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      coinbaseWallet({
        chains,
        options: {
          appName: "wagmi",
        },
      }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const Rainbowkit = ({ children }) => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        coolMode
        modalSize="compact" //wide,compact
        chains={chains}
        theme={darkTheme({
          accentColor: "linear-gradient(45deg, #ac50ef, #7059fb 50%, #2ecff6)",
          accentColorForeground: "white",
          borderRadius: "medium",
          fontStack: "system",
          overlayBlur: "small",
        })}
      >
        <UserProvider>{children}</UserProvider>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default Rainbowkit;
