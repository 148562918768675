import React, { useState, useEffect } from "react";
import useToast from "../../Common/Hooks/useToast";
import { saveWalletTransaction, toFixedd, getStage, getWalletHistory, getAdmin, waitForConfirmation } from "../../Helper";
import { useSendTransaction } from "wagmi";
import { parseEther } from "viem";
import { useUser } from "../../../context/UserContext";
import { useAccount, useBalance } from "wagmi";

const Buynow = () => {
  const { isConnected, address: addressData } = useAccount();
  const { isValidChain, switchToBNB, openConnectModal } = useUser();

  const { data: balanceof } = useBalance({ address: addressData });

  const { toastSuccess, toastError, toastWarning } = useToast();
  const { data: hash, error, isPending, sendTransaction } = useSendTransaction();

  const [loaderAct, setloaderAct] = useState(false);
  const [solAmount, setSolAmount] = useState("");
  const [totalBuyTokenByUser, setTotalBuyTokenByUser] = useState(0.0);
  const [solToken, setSolToken] = useState("");
  const [userBalance, setUserBalance] = useState("0:00");
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [presaleEnded, setPresaleEnded] = useState("$MEOW PRESALE IS LIVE");
  const intialData = {
    tokenPrice: 0,
    launchPrice: 0,
    raisedSol: 0,
    totalSol: 0,
    progressPercentage: 0,
    endDate: 0,
    adminPubKey: "",
    progressBar: false,
  };
  const [stageStatus, setStageStatus] = useState(intialData);

  useEffect(() => {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60;

    const calculateCountdown = () => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      if (distance < 0) {
        setPresaleEnded("Ends In");
        clearInterval(countdownInterval);
      } else {
        setPresaleEnded("$MEOW PRESALE IS LIVE");
        // Calculate total hours and remaining minutes and seconds
        const totalHours = Math.floor(distance / hour);
        const remainingMinutes = Math.floor((distance % hour) / minute);
        const remainingSeconds = Math.floor((distance % minute) / second);
        setHours(totalHours);
        setMinutes(remainingMinutes);
        setSeconds(remainingSeconds);
      }
    };

    // Set the countdown to 24 hours from now
    const countDownDate = Number(stageStatus.endDate);

    const countdownInterval = setInterval(calculateCountdown, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, [stageStatus.endDate]);

  useEffect(() => {
    if (isValidChain && isConnected && addressData) {
      let checkBalance = balanceof?.formatted ? Number(balanceof?.formatted).toFixed(4) : 0.0;
      setUserBalance(checkBalance);
      checkIfWalletIsConnected();
    } else {
      setUserBalance(0);
    }
  }, [isConnected, isValidChain, addressData, balanceof]);

  useEffect(() => {
    let isAPISubscribed = true;
    const getStageDetails = async () => {
      try {
        let stageData = await getStage();
        if (stageData?.data?.success) {
          const timestamp = new Date(stageData?.data?.data?.endDate);
          const percentage = (stageData?.data?.data?.raisedSol / stageData?.data?.data?.totalSol) * 100;
          setStageStatus({
            tokenPrice: stageData?.data?.data?.tokenPrice,
            launchPrice: stageData?.data?.data?.launchPrice,
            raisedSol: stageData?.data?.data?.raisedSol,
            totalSol: stageData?.data?.data?.totalSol,
            progressPercentage: percentage,
            adminPubKey: stageData?.data?.data?.adminPubKey,
            progressBar: stageData?.data?.data?.progressBar,
            endDate: timestamp.getTime(),
          });
        } else {
        }
      } catch (error) {
        console.error("Error in getWalletBalance:", error.message);
      }
    };
    if (isAPISubscribed) {
      getStageDetails();
    }
    return () => {
      isAPISubscribed = false;
    };
  }, []);

  // Sign a transaction
  const send = async () => {
    try {
      if (!isConnected) {
        return toastWarning("Please connect wallet first");
      }
      if (!isValidChain) {
        return toastWarning("Please select Ethereum network first");
      }

      if (!solAmount || solAmount <= 0) {
        return toastWarning("Please enter valid ETH amount");
      }

      if (Number(balanceof.formatted) < Number(solAmount)) {
        return toastWarning("You don't have enough ETH balance");
      }

      if (stageStatus.adminPubKey === "" || !stageStatus.adminPubKey) {
        return toastError("Try again");
      }

      setloaderAct(true);

      const getAdminAddress = await getAdmin(solAmount);

      if (!getAdminAddress?.status) {
        setloaderAct(false);
        return toastError("Please try again");
      }
      console.log("getAdminAddress.adminPubKey", getAdminAddress);

      sendTransaction({ to: getAdminAddress.address, value: parseEther(solAmount) });
    } catch (error) {
      setloaderAct(false);
      console.error("Error signing and sending transaction:", error);
      return toastError(error.message);
    }
  };

  const checkIfWalletIsConnected = async () => {
    try {
      if (isValidChain && isConnected) {
        let tokenBalance = await getWalletHistory(addressData);
        tokenBalance = tokenBalance?.data.success ? Number(tokenBalance.data.data.tokenBalance).toFixed(2) : "0.00";
        setTotalBuyTokenByUser(tokenBalance);
      } else {
        setTotalBuyTokenByUser("0.00");
        return false;
      }
    } catch (error) {
      setTotalBuyTokenByUser("0.00");
      console.error(error.message);
    }
  };

  const handleInput = (e) => {
    const inputValue = e.target.value;
    if (Number(inputValue) >= 0) {
      // let value = Number(inputValue) / Number(stageStatus.tokenPrice);
      let value = Number(inputValue) / 0.0000001;
      setSolAmount(inputValue);
      value = toFixedd(value);
      value = value ? value.toFixed(2) : value;
      setSolToken(value);
    } else {
      setSolAmount("");
      setSolToken("");
    }
  };

  useEffect(() => {
    if (hash) {
      const checkTransactionStatus = async () => {
        console.log("Transaction status", hash);
        const receipt = await waitForConfirmation(hash.hash);
        if (receipt) {
          let isSaved = await saveWalletTransaction(addressData, solToken, receipt, solAmount);
          if (isSaved.data.success) {
            checkIfWalletIsConnected();
            setSolAmount("");
            setSolToken("");
            setloaderAct(false);
            toastSuccess("Congratulations! Your transaction is confirmed on the blockchain. Once the presale concludes, you’ll be able to claim your tokens.");
          } else {
            setloaderAct(false);
            toastError("Transaction confirmed, getting error while saving transaction");
          }
        } else {
          setloaderAct(false);
          toastError("Transaction failed");
        }
      };
      checkTransactionStatus();
    }
    if (error) {
      setloaderAct(false);
      toastError("Transaction failed");
    }
  }, [hash, error, isPending]);

  return (
    <section className="banner-home" id="home">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="hero-text">
              <img src="images/img1.png" width="100%" alt="" />
              <h1>MEOW AI</h1>
              <h4>Elevating Your Crypto/NFT Trading Game with AI-Powered Intelligence</h4>
              <p>
                Welcome to MEOW AI, the avant-garde platform reshaping the landscape of crypto and NFT trading through state-of-the-art AI-driven blockchain
                intelligence. Dive into the future of trading success with MEOW AI - your strategic companion in navigating the complexities of the digital
                assets space.
              </p>
            </div>
          </div>
          <div className="col-md-4 ms-auto banner-right">
            <div className="swap-box">
              <div className="timer-box">
                <h4>{presaleEnded}</h4>
                <ul className="row">
                  <li className="col-md-4">
                    <div className="timer-box-in">
                      <h4>{hours}</h4>
                      <span>Hours</span>
                    </div>
                  </li>
                  <li className="col-md-4">
                    <div className="timer-box-in">
                      <h4>{minutes}</h4>
                      <span>Mins</span>
                    </div>
                  </li>
                  <li className="col-md-4">
                    <div className="timer-box-in">
                      <h4>{seconds}</h4>
                      <span>Sec</span>
                    </div>
                  </li>
                </ul>
                <div className="swap-box-head">
                  <h4>
                    Your Holdings: <b>{totalBuyTokenByUser} MEOW</b>
                  </h4>
                  <h4>
                    Funds Raised: <b>{stageStatus.raisedSol?.toFixed(2)} ETH</b>
                  </h4>
                  {stageStatus?.progressBar ? (
                    <div className="progress-box">
                      <span style={{ width: `${stageStatus.progressPercentage?.toFixed(2)}%` }}></span>
                      <p>{Number(stageStatus.progressPercentage).toFixed(0)}%</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="swap-box-in">
                <div className="swap-form">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <div className="d-flex mb-2">
                          <label>Amount in ETH You Pay:</label>
                          <span className="ms-auto">
                            <i className="ri-wallet-line"></i> {userBalance} ETH
                          </span>
                        </div>
                        <div className="form-input">
                          <input type="number" placeholder="Min 0.03 ETH | Max 2 ETH" value={solAmount} onChange={handleInput} className="form-control" />
                          <div className="input-icon">
                            <span>
                              <img src="images/Ethereum-ETH-icon.png" alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group mb-3">
                        <label>Amount in $MEOW You Receive:</label>
                        <div className="form-input">
                          <input type="number" name="" value={solToken} disabled className="form-control" />
                          <div className="input-icon">
                            <span>
                              <img src="images/icon.png" alt="" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="presale-action my-3">
                    {isConnected ? (
                      isValidChain ? (
                        loaderAct ? (
                          <>
                            <button className="w-100 btn btn-blue mt-2" disabled={true}>
                              Processing ...
                            </button>
                          </>
                        ) : (
                          <>
                            <button className="w-100 btn btn-blue mt-2" onClick={send}>
                              Buy Now
                            </button>
                          </>
                        )
                      ) : (
                        <button
                          className="btn w-100  btn-blue "
                          onClick={() => {
                            switchToBNB();
                          }}
                        >
                          Switch to Ethereum
                        </button>
                      )
                    ) : (
                      <button className="btn w-100 btn-blue " onClick={openConnectModal}>
                        Connect Wallet
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Buynow;
