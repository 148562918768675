import { createContext, useContext, useEffect, useState } from "react";
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from "wagmi";
import { useAccountModal, useConnectModal } from "@rainbow-me/rainbowkit";

const chainID = 1;

const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);

function UserProvider({ children }) {
  const { chain } = useNetwork();
  const [isValidChain, setIsValidChain] = useState(false);
  const [chainName, setChainName] = useState(false);
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork({
    chainId: chainID,
    throwForSwitchChainNotSupported: true,
    onSuccess: (id) => {
      setIsValidChain(true);
    },
    onError: (er) => {
      disconnect();
    },
  });

  const switchToBNB = async () => {
    try {
      switchNetwork();
    } catch (err) {
      window.location.reload();
    }
  };

  const disconnectBtn = async () => {
    try {
      disconnect();
    } catch (err) {
      console.log("disconnedt error: ", err.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isConnected) {
        setChainName(chain.name);
        if (chain.id === Number(chainID)) {
          setIsValidChain(true);
        } else {
          setIsValidChain(false);
        }
      } else {
        setChainName(false);
      }
    };
    fetchData();
  }, [isConnected, chain, address]);

  return (
    openAccountModal,
    (
      <UserContext.Provider value={{ isValidChain, setIsValidChain, chainName, setChainName, disconnectBtn, switchToBNB, openConnectModal, openAccountModal }}>
        {children}
      </UserContext.Provider>
    )
  );
}

export default UserProvider;
